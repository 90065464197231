export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'zh-Hans',
  globalInjection: true,
  fallbackLocale: {
    'de-CH': ['fr', 'it'],
    'zh-Hant': ['zh-Hans'],
    'es-CL': ['es-AR'],
    es: ['en-GB'],
    pt: ['es-AR'],
    default: ['en', 'zh-Hans'],
  },
  useCookie: true,
  messages: {
    en: {
      welcome: 'Welcome',
    },
    'zh-Hans': {
      welcome: '欢迎',
    },
    fr: {
      welcome: 'Bienvenue',
    },
    ja: {
      message: {
        hello: 'こんにちは、世界',
      },
    },
  },
}))
